<template>
  <PageContainer
    :title="$translations['forgot-password'].title"
    :back="actions.back"
  >
    <template v-slot:content>
      <div class="page-segment">
        <div class="page-segment-content">
          <FormChangePassword
            @valid="validation = $event"
            @changedNewPassword="newPassword = $event"
          />

          <Button
            :text="$translations['forgot-password'].verification.button.update"
            :disable="!isValid"
            :loading="loadingButton"
            @clicked="updatePassword"
          />
        </div>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import { PageContainer, Button } from '@seliaco/red-panda'
import FormChangePassword from '@/components/sections/FormChangePassword'

export default {
  components: {
    PageContainer,
    FormChangePassword,
    Button
  },
  data () {
    return {
      loadingButton: false,
      validation: false,
      newPassword: '',
      code: this.$route.query.code,
      actions: {
        back: {
          callback: () => {
            this.$router.replace({ name: 'SignIn' })
          }
        }
      }
    }
  },
  methods: {
    updatePassword () {
      this.loadingButton = true
      this.$auth
        .onChangePassword(this.code, this.newPassword)
        .then(() => {
          this.$toast({
            severity: 'success',
            text: this.$translations.auth['change-password-successfully']
          })
          this.$router.push({ name: 'SignIn' })
        })
        .catch(() => {
          this.$toast({
            severity: 'error',
            text: this.$translations.error['change-password']
          })
        })
        .finally(() => {
          this.loadingButton = false
        })
    }
  },
  computed: {
    isValid () {
      return Boolean(this.newPassword && this.validation)
    }
  }
}
</script>
