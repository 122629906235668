<template>
  <PageContainer
    :title="$translations['forgot-password'].title"
    :back="actions.back"
  >
    <template v-slot:content>
      <div class="page-segment">
        <div class="page-segment-content">
          <div
            class="content-title"
            v-text="$translations['forgot-password'].subtitle"
          />
          <div
            class="body margin-top"
            v-text="$translations['forgot-password'].subtitle2"
          />
          <div class="margin-top-xl">
            <InputTextField
              :title="$translations.form.email"
              id="email"
              :value="form.email"
              :label="$translations.form['email-placeholder']"
              type="email"
              v-model="form.email"
            />
            <Button
              class="margin-top-xl"
              :text="$translations['forgot-password'].send"
              :disable="!isValid"
              :loading="loading"
              @clicked="submit"
            />
          </div>
        </div>
      </div>
    </template>
  </PageContainer>
</template>
<script>
import { PageContainer, Button } from '@seliaco/red-panda'
import { InputTextField } from '@seliaco/sea-otter'

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export default {
  components: {
    PageContainer,
    InputTextField,
    Button
  },
  data () {
    return {
      loading: false,
      form: {
        email: ''
      },
      actions: {
        back: {
          callback: () => {
            this.$router.replace({ name: 'SignIn' })
          }
        }
      }
    }
  },
  methods: {
    submit () {
      this.loading = true
      this.$auth
        .onSendPasswordResetEmail(this.form.email)
        .then(() => {
          this.$toast({
            severity: 'success',
            text: this.$translations.auth['verification-code-title']
          })
          this.$router.push({ name: 'VerificationCode' })
        })
        .catch(() => {
          this.$toast({
            severity: 'error',
            text: this.$translations.error['forgot-password']
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  computed: {
    isValid () {
      return Boolean(this.form.email) && validateEmail(this.form.email)
    }
  }
}
</script>
