<template>
  <PageContainer
    :title="$translations['forgot-password'].title"
    :back="actions.back"
  >
    <template v-slot:content>
      <div class="page-segment">
        <div class="page-segment-content">
          <div
            class="font-bold"
            v-text="$translations['forgot-password'].verification.title"
          />
          <div
            class="body text-black-80 margin-top"
            v-text="$translations['forgot-password'].verification.subtitle"
          />
          <div class="flex margin-y-xl justify-center">
            <CodeInput
              class="input"
              :loading="false"
              @change="onChange"
              @complete="onComplete"
            />
          </div>
          <div
            class="body text-black-50 w-full text-center margin-bottom-xl"
            v-text="$translations['forgot-password'].verification.retry"
          />
          <Button
            :text="$translations['forgot-password'].verification.button.verify"
            :disable="!isValid"
            :loading="loading"
            @clicked="verify()"
          />
        </div>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import { PageContainer, Button } from '@seliaco/red-panda'
import CodeInput from 'vue-verification-code-input'

export default {
  components: {
    PageContainer,
    CodeInput,
    Button
  },
  computed: {
    isValid () {
      return this.code.length === 6
    }
  },
  data () {
    return {
      loading: false,
      isLoading: true,
      code: '',
      actions: {
        back: {
          callback: () => {
            this.$router.go(-1)
          }
        }
      }
    }
  },
  methods: {
    onChange (value) {
      this.code = value
    },
    onComplete (value) {
      this.code = value
    },
    verify () {
      this.loading = true
      this.$auth
        .onValidateCode(this.code)
        .then(() => {
          this.$router.push({
            name: 'ChangePassword',
            query: {
              code: this.code
            }
          })
        })
        .catch(() => {
          this.$toast({
            severity: 'error',
            text: this.$translations.error['invalid-code']
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="sass">
.react-code-input
  @apply space-x-1
  input
    width: 48px !important
    display: inline
</style>
